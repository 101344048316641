<template>
  <section class="login-wrapper forgot-password" data-app>
 <div class="form-body">
        <div class="row">
            <div class="img-holder">
                <div class="bg"></div>
                <div class="info-holder">
                    <h3>Welcome to KoloBox</h3>
                    <p>We make it easy to invest in right product, Let your money work for you</p>
                    <img src="@/assets/images/4002800.svg" alt="">
                </div>
            </div>
            <div class="form-holder">
                <div class="form-content">
                    <div class="form-items">
                        <div class="website-logo-inside">
                            <a href="">
                                <div class="logo">
                                    <!-- <img class="logo-size" src="https://fe-staging.kolobox.ng/img/logo-kolobox.fff5e8f1.png" alt=""> -->
                                    <img class="logo-size" src="images/logo-light.svg" alt="">
                                </div>
                            </a>
                        </div>
                        <h3>Password Reset</h3>
                        <p>To reset your password, enter the email address you used to sign in to kolobox</p>
                        <form
                          action=""
                method="POST"
                class="form-login-pages form-forgot-password-pages"
                id="forgot-password-form"
                v-on:submit.prevent="validate">
                            <input class="form-control" name="registeredEmail" 
                            id="registeredEmail"
                      v-validate="'required|email'"
                      v-model="email"
                      type="email"
                            placeholder="E-mail Address" required>
                             <span
                      v-show="errors.has('registeredEmail')"
                      class="text-danger"
                      style="font-size:9px;font-weight:bold;"
                      >{{ errors.first("registeredEmail") }}</span
                    >
                            <div class="form-button full-width">
                                <button id="submit" type="submit" class="ibtn btn-forget">Send Reset Link</button>
                            </div>
                        </form>
                    </div>
                  
                </div>
            </div>
        </div>
    </div>
    <v-snackbar v-model="toast.show" top right color="black">
      {{ toast.msg }}
      <v-btn flat dark small @click.native="toast.show = false">Close</v-btn>
    </v-snackbar>
  </section>
</template>

<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-HP1WKQF3LQ"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-HP1WKQF3LQ');
</script>

<script>
import { setTimeout } from "timers";
export default {
  data() {
    return {
      email: "",
      toast: {
        show: false,
        msg: ""
      }
    };
  },
  methods: {
    validate() {
      this.$validator
        .validateAll()
        .then(result => {
          if (result) this.submit();
        })
        .catch(e => {
          this.toast.msg = "Oops! An error occured. Please try again";
          this.toast.show = true;
        });
    },
    submit() {
      this.toast.msg = "Sending...";
      this.toast.show = true;
      this.$http
        .post(
          "auth/user/forget_password",
          { emailPhone: this.email },
          { emulateJSON: true }
        )
        .then(res => {
          this.toast.msg =
            res.body.data.message ||
            "Successful! Please visit your mail, to confirm.";
          this.email = "";
          setTimeout(() => {
            this.$router.push("/signin");
          }, 3000);
          let temporaryToken = localStorage.getItem("token");
          localStorage.setItem("temporaryToken", temporaryToken);
          localStorage.removeItem("token");
        })
        .catch(e => {
          this.toast.msg =
            e.body.message ||
            e.body.message.message ||
            "Oops! An error occured. Please try again";
        });
    }
  }
};
</script>
