<template>
  <section class="login-wrapper" data-app>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-sm-9 login-outer">
          <div class="row ">
            <div class="col-sm-6 left-area">
              <img src="@/assets/images/logo-kolobox.png" />
            </div>
            <div class="col-sm-6 right-area">
              <h3 id="welcome-text">Welcome to <span>KoloBox</span></h3>
              <p id="sub-heading">
                We make it easy to invest in right product, Let your money work
                for you.
              </p>
              <form
                action=""
                method="POST"
                class="form-login-pages"
                id="login-form"
                v-on:submit.prevent="validateForm"
              >
                <div class="input-group">
                  <div class="input-outer">
                    <img src="@/assets/images/email.png" /><input
                      type="password"
                      v-validate="'required|min:6'"
                      class="form-control"
                      placeholder="password"
                      name="password"
                      v-model="form.new_password"
                    />
                    <span
                      v-show="errors.has('password')"
                      class="text-danger"
                      style="font-size:9px;font-weight:bold;"
                      >{{ errors.first("password") }}</span
                    >
                  </div>
                  <div class="input-outer">
                    <img src="@/assets/images/email.png" /><input
                      type="password"
                      v-validate="{ required: true, is: form.new_password }"
                      class="form-control"
                      placeholder="confirm password"
                      name="cpassword"
                      v-model="form.new_cpassword"
                    />
                    <span
                      v-show="errors.has('cpassword')"
                      class="text-danger"
                      style="font-size:9px;font-weight:bold;"
                      >{{ errors.first("cpassword") }}</span
                    >
                  </div>
                </div>

                <div class="bottom-form-area">
                  <div class="buttons-wrapper">
                    <button type="submit" class="btn-submit">CONFIRM</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-snackbar v-model="toast.show" top right color="black">
      {{ toast.msg }}
      <v-btn flat dark small @click.native="toast.show = false">Close</v-btn>
    </v-snackbar>
  </section>
</template>


<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-HP1WKQF3LQ"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-HP1WKQF3LQ');
</script>
<script>
export default {
  data() {
    return {
      form: {
        new_password: "",
        new_cpassword: ""
      },
      toast: {
        msg: "",
        show: false
      }
    };
  },
  methods: {
    validateForm() {
      this.$validator
        .validateAll()
        .then(result => {
          if (result) this.submit();
        })
        .catch(e => {
          this.toast.msg = "Oops! An error occured. Please try again";
          this.toast.show = true;
        });
    },
    submit() {
      this.toast.msg = "Updating...";
      this.toast.show = true;

      let temporaryToken = localStorage.getItem("temporaryToken");
      localStorage.setItem("token", temporaryToken);

      this.$http
        .post("auth/user/password_reset/change_password", this.form, {
          emulateJSON: true
        })
        .then(res => {
          this.toast.msg = "Your password has been changed. Login.";
          this.toast.show = true;
          //Remove the token used to change Password
          localStorage.removeItem("token");
          localStorage.removeItem("temporaryToken");
          setTimeout(() => {
            this.$router.push("/signin");
          }, 1200);
        })
        .catch(e => {
          this.toast.msg =
            e.body.message ||
            e.body.message.message ||
            "Oops! an error occured";
        });
    }
  }
};
</script>

<style scoped>
.link {
  color: blue !important;
}
</style>
